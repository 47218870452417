<template>
  <div class="container">
    <div class="title">企业认证</div>
    <div class="line"></div>
    <div class="imgWrap" v-loading="loading">
      <imgSlider :imgOption="imgOption"></imgSlider>
      <div v-if="imgOption.imgList.length === 0">
        <noDataImg></noDataImg>
      </div>
    </div>
  </div>
</template>

<script>
  import imgSlider from '~scb/components/common/imgSlider'
  export default {
    name: "companyAuthentication",
    components: { imgSlider },
    props: {
      company_id: {
        type: String | Number,
        default: ''
      }
    },
    data() {
      return {
        loading: false,
        imgData: [],
        imgOption: {
          width: "240px",
          height: "167px",
          totalWidth: 850,
          imgList: []
        }
      }
    },
    created() {
      this.getData()
    },
    methods: {
      async getData() {
        let params = {
          company_id: this.company_id,
          nopage: 1
        }
        this.loading = true
        try {
          let res = await this.$store.dispatch("API_company/getCompanyCertificate",params)
          this.loading = false
          if (res.success) {
            this.imgData = res.data;
            this.handleData(this.imgData);
          }
        } catch (e) {
        }
      },
      //数据处理
      handleData(data) {
        let ary = [];
        data.forEach(item => {
          ary.push(item.url);
        });
        if (ary.length > 0) {
          this.imgOption.imgList = ary;
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .container {
    margin-top: 20px;
    padding: 24px;
    background: #FFFFFF;
    .title {
      color: #333333;
      font-size: 16px;
      font-weight: 800;
    }
    .line {
      height: 1px;
      background: #DCDFE6;
      width: 100%;
      margin-top: 20px;
    }
    .imgWrap {
      margin-top: 16px;
      position: relative;
    }
  }
</style>